body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.loader {
    border: 1px solid #f3f3f3; /* Light grey */
    border-top: 1px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 700ms linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.full-spinner {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
    display: table-cell; }
.full-spinner img {
    vertical-align: middle; }
.full-spinner .loader-inner {
    height: 120px;
    width: 120px;
    margin: auto;
    vertical-align: middle;
    margin-top: 20%; }

#colormapchromosome {
    margin: 300
}
